import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddIleggelseToKlageModal from "./AddIleggelseToKlageModal";
import {
  getIleggelseDetailByNummer,
  getKlageIleggelser,
  addKlageIleggelse,
  setKlageIleggelseInReducer,
} from "../../actions/klager/klageAction";
import { getIleggelseRettelser } from "../../actions/ileggelseRettelserActions";
import { getMiljoRettelser } from "../../actions/miljoRettelserActions";
import { handleChangeAll as handleChangeAllAction } from "../../actions/handleChangeAction";
import {
  addKlageVedtak,
  getKlageVedtaker,
} from "../../actions/klager/vedtakAction";
import { filter, first, isEmpty } from "lodash";
import { IsValidated, onChangeValidation } from "../../utils/validationUtils";

export class IleggelseTabContainer extends Component {
  static propTypes = {
    klageId: PropTypes.string,
    changeSelectedTab: PropTypes.func,
    handleChangeAllVedtakerFormAction: PropTypes.func.isRequired,
    onAddIleggelse: PropTypes.func,
    manglerInfo: PropTypes.bool,
  };

  state = {
    open: false,
    ileggelsenummer: "",
    validationError: {},
    disabled: false,
    showSubmitSpinner: false,
  };

  rules = {
    ileggelsenummer: "required;isDigit",
  };

  closeModal = () => {
    this.setState({ open: false, validationError: {}, ileggelsenummer: "" });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    onChangeValidation(e, this.state, this.rules);
  };

  getIleggelseTilbakeMeldinger = (ileggelse) => {
    const ileggelseId = ileggelse.id;
    const ileggelsetype = ileggelse.ileggelsestypeId;
    ileggelsetype && ileggelsetype !== 5
      ? this.props.getIleggelseRettelser(ileggelseId)
      : this.props.getMiljoRettelser(ileggelseId);
  };

  addIleggelseToKlageOgVedtak = (e) => {
    e.preventDefault();
    const { handleChangeAllVedtakerFormAction } = this.props;
    if (this.isValid()) {
      this.props
        .getIleggelseDetailByNummer(this.state.ileggelsenummer, null, false)
        .then((ileggelse) => {
          if (ileggelse && ileggelse.ileggelsesnummer) {
            this.setState({ disabled: true, showSubmitSpinner: true });
            this.props
              .addKlageIleggelse(this.props.klageId, ileggelse)
              .then(async () => {
                await this.props.setKlageIleggelseInReducer(ileggelse);
                this.getIleggelseTilbakeMeldinger(ileggelse);
                await this.props.getKlageIleggelser(this.props.klageId);
                await this.props.onAddIleggelse(ileggelse.ileggelsesnummer);
                if (
                  this.vedtaksIleggelseNotExists(ileggelse.ileggelsesnummer)
                ) {
                  var klagevedtak = await this.props
                    .addKlageVedtak(this.props.klageId, ileggelse)
                    .catch(() => {
                      this.setState({
                        disabled: false,
                        showSubmitSpinner: false,
                        open: false,
                        ileggelsenummer: "",
                      });
                    });
                  if (klagevedtak && klagevedtak.id) {
                    const klagevedtaker = await this.props.getKlageVedtaker(
                      this.props.klageId
                    );
                    const vedtakerForm = klagevedtaker.reduce(
                      (object, klagevedtak) => {
                        object[klagevedtak.id] = {
                          vedtakskode: klagevedtak.vedtakskodeId,
                          validationError: {},
                        };
                        return object;
                      },
                      {}
                    );
                    handleChangeAllVedtakerFormAction(vedtakerForm);
                  }
                }
                this.setState({
                  open: false,
                  ileggelsenummer: "",
                  disabled: false,
                  showSubmitSpinner: false,
                });
              })
              .catch(() =>
                this.setState({ disabled: false, showSubmitSpinner: false })
              );
          } else {
            const validationError = {
              ileggelsenummer: `Fant ikke ileggelse/miljøgebyr med ileggelsesnummer ${ileggelse.ileggelsesnummer}.`,
            };
            this.setState({
              validationError,
              open: true,
              disabled: false,
              showSubmitSpinner: false,
            });
          }
        });
    }
  };

  vedtaksIleggelseNotExists = (ileggelsesnummer) => {
    const vedtaker = this.props.vedtaker;
    const ileggelse = first(
      filter(vedtaker, (i) => {
        return i.ileggelsesnummer.toString() === ileggelsesnummer.toString();
      })
    );
    return isEmpty(ileggelse);
  };

  render() {
    return (
      <AddIleggelseToKlageModal
        open={this.state.open}
        openModal={this.openModal}
        closeModal={this.closeModal}
        handleChange={this.handleChange}
        ileggelsenummer={this.state.ileggelsenummer}
        onSubmit={this.addIleggelseToKlageOgVedtak}
        disabled={this.state.disabled || this.props.manglerInfo}
        showSubmitSpinner={this.state.showSubmitSpinner}
        id="addIleggelser"
        validationError={this.state.validationError}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  klageId:
    state.klageDetail && state.klageDetail.id ? state.klageDetail.id : "",
  vedtaker: state.vedtaker,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getIleggelseDetailByNummer,
      addKlageIleggelse,
      addKlageVedtak,
      getKlageIleggelser,
      getKlageVedtaker,
      getIleggelseRettelser,
      getMiljoRettelser,
      setKlageIleggelseInReducer,
      handleChangeAllVedtakerFormAction: (payload) =>
        handleChangeAllAction("KLAGE_VEDTAKER_FORM", payload),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IleggelseTabContainer);
