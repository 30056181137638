import { getEnvironmentAppendix } from "../../utils/envUtils.js";

const environmentKey = "REACT_APP_ENV";
const isWindowDefined = typeof window !== "undefined";
const environment =
  (isWindowDefined ? window[environmentKey] : undefined) ||
  process.env[environmentKey];
export const IsLocal = environment && environment === "local";
export const IsDevelopment = environment && environment === "dev";
export const IsExternalDevelopment =
  environment && environment === "externaldev";
export const IsTesting = environment && environment === "test";
export const IsProduction = environment && environment === "prod";

console.log("Environment is: ", environment);

export function getHostFromEnv(url_name) {
  if (typeof window === "undefined") return "";
  const key = "REACT_APP_" + url_name + getEnvironmentAppendix();
  const host = window[key] || process.env[key];
  console.log(`${key} host:`, host);
  if (host != null) return host;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

export const ILEGGELSE_SERVICE_BASE_URL =
  getHostFromEnv("URL_SERVICE") + "/api";

export const INNTAUING_WEB_BASE_URL = getHostFromEnv("URL_INNTAUING_WEB");
