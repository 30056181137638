import { ILEGGELSE_SERVICE_BASE_URL } from "../config.ts";

// - Version
export const GET_ILEGGELSE_SERVICE_VERSION_URL = `${ILEGGELSE_SERVICE_BASE_URL}/version`;
export const GET_INE_STATUS = `${ILEGGELSE_SERVICE_BASE_URL}/get_ine_status`;

// - generic apiurl bulider
export const apiUrlBuilder = (controllerName, id) => {
  if (id && id !== "")
    return `${ILEGGELSE_SERVICE_BASE_URL}/${controllerName}/${id}`;
  else return `${ILEGGELSE_SERVICE_BASE_URL}/${controllerName}`;
};

export * from "./aktorer";
export * from "./betjenter";
export * from "./bilder";
export * from "./billett";
export * from "./brevStatistikk";
export * from "./farger";
export * from "./folgebrev";
export * from "./forsinketMakulering";
export * from "./gateAdresser";
export * from "./ileggelse";
export * from "./ileggelseClientLog";
export * from "./ineReports";
export * from "./internkodeIleggelse";
export * from "./kjoretoy";
export * from "./klager";
export * from "./miljogebyr";
export * from "./motorvogn";
export * from "./mottakertype";
export * from "./nasjoner";
export * from "./overtredelser";
export * from "./arkiv";
export * from "./rapporter";
export * from "./rettekoder";
export * from "./skift";
export * from "./stedbeskrivelser";
export * from "./uregistrerte";
export * from "./vedtakskoder";
export * from "./ventilstilling";
