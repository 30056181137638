import sitePaths from "./sitePaths";

export const landingPages = {
  ileggelseAdmin: sitePaths.ileggelser.oversikt.shortUrl,
  stedfortrederDriftsleder: sitePaths.ileggelser.oversikt.shortUrl,
  bypatruljen: sitePaths.ileggelser.oversikt.shortUrl,
  juridisk: sitePaths.klage.shortUrl,
  innsyn: sitePaths.innsyn.ileggelser.oversikt,
  skiftlogg: sitePaths.rapporter.skiftlogg.oversikt,
};

export default landingPages;
