import axios from "../services/axios";
import * as api from "../constants/api";
import { popUpBlocked } from "../constants/ErrorMessages";
import { addFlashMessage } from "./FlashMessagesAction";

export const getIleggelseEttersendelserEttersendelsebrevPdf = (
  ileggelseEttersendelseBrevId
) => {
  return async (dispatch) => {
    return axios
      .get(
        api.GET_ILEGGELSEETTERSENDELSER_ETTERSENDELSEBREVPDF_URL(
          ileggelseEttersendelseBrevId
        )
      )
      .then((response) => {
        let tabName = "E-Brev.pdf";
        var byteCharacters = window.atob(response.data.result.base64_dokument);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });
        window.URL = window.URL || window.webkitURL;
        var blobUrl = window.URL.createObjectURL(blob);
        let pdfWindow = window.open("");
        if (
          !pdfWindow ||
          pdfWindow.closed ||
          typeof pdfWindow.closed === "undefined"
        ) {
          throw new Error(popUpBlocked);
        } else {
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" +
              blobUrl +
              "' frameborder='0' allowfullscreen></iframe>"
          );
          pdfWindow.document.title = tabName;
        }
        return true;
      })
      .catch((error) => {
        if (error.message) {
          dispatch(
            addFlashMessage({
              type: "error",
              text: error.message,
              allowDangerouslySetInnerHtml: true,
            })
          );
          return "popUpBlocked";
        }
        return false;
      });
  };
};

export function getAllIleggelseEttersendelserbreverPdf(params) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ALL_ILEGGELSEETTERSENDELSER_BREVER_PDF_URL, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        let tabName = "E-Brev.pdf";
        var byteCharacters = window.atob(response.data.result.base64String);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });
        window.URL = window.URL || window.webkitURL;
        var blobUrl = window.URL.createObjectURL(blob);
        let pdfWindow = window.open("");
        if (
          !pdfWindow ||
          pdfWindow.closed ||
          typeof pdfWindow.closed === "undefined"
        ) {
          throw new Error(popUpBlocked);
        } else {
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" +
              blobUrl +
              "' frameborder='0' allowfullscreen></iframe>"
          );
          pdfWindow.document.title = tabName;
        }
        return true;
      })
      .catch((error) => {
        if (error.message) {
          dispatch(
            addFlashMessage({
              type: "error",
              text: error.message,
            })
          );
          return "popUpBlocked";
        }
        return false;
      });
  };
}
