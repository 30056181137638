const interne = "Interne";
const offentlige = "Offentlige";
const filressurser = "Filressurser";

const interneLinker = [
  {
    tittel: "Permanente skiltforskrifter i Qlik fra 20.01.2025",
    link: "https://bym-qlik.bymoslo.no/oslofelles/sense/app/1f1c33b5-ee84-4fee-833c-dcaf899a34ed/sheet/e6e24419-18ba-4207-8777-a72c01341856/state/analysis",
    kategori: interne,
  },
  {
    tittel: "INE-PAX fra 08.12.2022",
    link: "https://pax.ine.oslo.kommune.no/RDWeb/webclient/",
    kategori: interne,
  },
  {
    tittel: "Inntauingsløsning fra 01.12.2022",
    link: "https://inntauing.bymoslo.no/login",
    kategori: interne,
  },
  {
    tittel: "Qlik Sense Hub",
    link: "https://bym-qlik.bymoslo.no/oslofelles/hub/stream/6c369999-678a-4953-a4e9-aef266306454",
    kategori: interne,
  },
  {
    tittel: "Plan og bygningsetatens eiendomsregister",
    link: "http://10.192.10.166/webmatrikkel/Matrikkel.aspx?knr=301",
    kategori: interne,
  },
  {
    tittel: "Maintenance automatfeil/ladestolpefeil",
    link: "https://oslokommune.myview.cloud",
    kategori: interne,
  },
  {
    tittel: "STR Sak",
    link: "https://strsak.bymoslo.no/",
    kategori: interne,
  },
];

const offentligeLinker = [
  { tittel: "Lovdata", link: "https://lovdata.no/", kategori: offentlige },
  {
    tittel: "Google Maps",
    link: "https://www.google.no/maps/",
    kategori: offentlige,
  },
  {
    tittel: "Infotorg",
    link: "https://www.infotorg.no/",
    kategori: offentlige,
  },
  {
    tittel: "Bil i Oslo",
    link: "https://bilioslo.bymoslo.no/",
    kategori: interne,
  },
  {
    tittel: "Piggdekkoblat-Admin",
    link: "https://piggav.bymoslo.no/login",
    kategori: interne,
  },
  {
    tittel: "EasyPark",
    link: "https://dashboard.easypark.net/",
    kategori: interne,
  },
  {
    tittel: "Prinsippavgjørelser Parkeringsklagenemnda",
    link: "http://pklagenemnda.no/prinsippavgjorelser/",
    kategori: offentlige,
  },
  {
    tittel: "Kart over betalingsautomater",
    link: "http://www.arcgis.com/apps/webappviewer/index.html?id=8e6ab845c33342d1811259b0abc49a34&extent=593512.5204,6641105.451,601640.5367,6645010.7088,25832",
    kategori: offentlige,
  },
  {
    tittel: "Cale automat",
    link: "https://weboffice2.caleaccess.com/Cwo2/UserLogin.aspx",
    kategori: interne,
  },
  {
    tittel: "Enhetsregistret - Brønnøysundregistret",
    link: "https://w2.brreg.no/enhet/sok/",
    kategori: offentlige,
  },
  {
    tittel: "Svar Ut",
    link: "https://svarut.ks.no/",
    kategori: offentlige,
  },
  {
    tittel: "Folkeregistret",
    link: "https://forvaltning.fiks.ks.no",
    kategori: offentlige,
  },
];

const filressurserLinker = [
  {
    tittel: "Tverrfaglig - Sharepoint",
    link: `https://oslokommune.sharepoint.com/sites/13154/Tverrfaglig/Forms/AllItems.aspx`,
    kategori: filressurser,
  },
];

export { interneLinker, offentligeLinker, filressurserLinker };
