import { ILEGGELSE_SERVICE_BASE_URL } from "../config.ts";

// - Ileggelser
export const GET_ILEGGELSER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser`;

export const PUT_ILEGGELSE_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}`;

export const GET_ILEGGELSE_KOPI_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/kopi`;

export const GET_ILEGGELSER_ILEGGELSEKLAGER = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/IleggelseKlager`;

// - Ileggelser ettersendelsebrev
export const GET_ILEGGELSEETTERSENDELSEBREVER_URL = (ettersendelseIds) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseettersendelsebrever`;
export const GET_ILEGGELSEETTERSENDELSER_ETTERSENDELSEBREVPDF_URL = (
  ileggelseEttersendelseBrevId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseettersendelsebrev/${ileggelseEttersendelseBrevId}/EttersendelseBrevPdf`;

// - Ileggelser ettersendelsebrev
export const GET_ALL_ILEGGELSEETTERSENDELSER_BREVER_PDF_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseettersendelsebrever`;

// - Ileggelse
export const GET_ILEGGELSE_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}`;

export const GET_ILEGGELSER_BILDER_BASE_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/bilder`;

export const GET_ILEGGELSE_BILDE_BASE64_URL = (ileggelseId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/bilder/${bildeId}/base64`;

export const POST_ILEGGELSER_BILDER_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/bilder`;

export const PUT_ILEGGELSER_BILDER_URL = (ileggelseId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/bilder/${bildeId}`;

export const DELETE_ILEGGELSER_BILDER_URL = (ileggelserId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelserId}/bilder/${bildeId}`;

// - Ileggelse rettelser
export const GET_ILEGGELSE_RETTELSER_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/ileggelseRettelser`;

// - Ileggelse historikk
export const GET_ILEGGELSE_HISTORIKK = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/historikk`;

// - IleggelseEttersendelser
export const GET_ILEGGELSE_ETTERSENDELSER = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/ileggelseEttersendelser`;
export const POST_ILEGGELSE_ETTERSENDELSER = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/ileggelseEttersendelser`;
export const PUT_ILEGGELSE_ETTERSENDELSER = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseEttersendelser/${ileggelseId}`;

export const PUT_ILEGGELSE_TIL_ETTERSENDELSE_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/isTilEttersendelse`;

export const POST_ILEGGELSE_ETTERSENDELSESBREV = (ettersendelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseettersendelser/${ettersendelseId}/ettersendelsebrev`;

export const PUT_ILEGGELSE_ETTERSENDELSER_TIL_GODKJENNING = (
  ileggelseEttersendelseId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseEttersendelser/${ileggelseEttersendelseId}/tilGodkjenning`;

// - IleggelseMakulering
export const GET_ILEGGELSE_MAKULERINGER_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/ileggelseMakuleringer`;

export const POST_ILEGGELSER_MAKULERING_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/ileggelseMakuleringer`;

export const DELETE_ILEGGELSE_MAKULERINGER_URL = (ileggelseMakuleringId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseMakuleringer/${ileggelseMakuleringId}`;

export const PUT_ILEGGELSER_SETT_KLAR_TIL_INE_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/settklartilkemner`;

// - Ileggelse forsinket makulering
export const POST_ILEGGELSER_FORSINKET_MAKULERING = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/forsinketmakulering`;

export const DELETE_ILEGGELSER_FORSINKET_MAKULERING = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/forsinketmakulering`;

// ileggelseKommentarer
export const CREATE_ILLEGGELSE_KOMMENTAR_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/kommentarer`;

export const GET_ALL_ILLEGGELSE_KOMMENTARER_URL = (ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/kommentarer`;

export const GET_SINGLE_ILLEGGELSE_KOMMENTAR_URL = (ileggelseId, kommentarId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/kommentarer/${kommentarId}`;

export const UPDATE_ILLEGGELSE_KOMMENTAR_URL = (ileggelseId, kommentarId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelser/${ileggelseId}/kommentarer/${kommentarId}`;

// - Ileggelsestyper
export const GET_ILEGGELSESTYPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelsestyper`;

// - Ileggelse Leveringstyper
export const GET_ILEGGELSELEVERINGSTYPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseleveringstyper`;
