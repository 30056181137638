import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Redirect, Prompt, Route } from "react-router-dom";
import cuid from "cuid";

import PublicRoute from "./components/Routes/PublicRoute";
import PrivateRoute from "./components/Routes/PrivateRoute";

import AuthorizedRoutes from "./components/Routes/AuthorizedRoutes";

import NavigationBar from "./components/Layout/NavigationBarContainer";
import FlashMessagesListContainer from "./components/Common/FlashMessagesListContainer";

import IleggelserRoutes from "./Routes/Ileggelser";
import MiljogebyrerRoutes from "./Routes/Miljogebyrer";
import UregistrerteRoutes from "./Routes/Uregistrerte";
import KlageRoutes from "./Routes/Klage";
import RapporterRoutes from "./Routes/Rapporter";
import InnkrevingRoutes from "./Routes/Innkreving";
import InnsynRoutes from "./Routes/Innsyn";
import Linker from "./Routes/Linker";

import RedirectPage from "./components/RedirectPage";
import VersionPage from "./components/Version/VersionPage";
import LoginPage from "./components/Login/LoginPage";
import LandingPageContainer from "./components/LandingPageContainer";
import ProfilePage from "./components/Profile/ProfilePage";
import GrunndataPage from "./components/Grunndata/GrunndataPage";

import ModalGeneric from "./components/Common/ModalGeneric";

import { authorized } from "./utils/authorizationUtils";

import paths from "./constants/sitePaths";
import {
  ileggelseadmin,
  bypatruljen,
  skiftlogg,
  juridisk,
  innsyn,
  bypatruljenArray,
  juridiskArray,
  innsynArray,
  bypatruljenAndJuridiskArray,
} from "./constants/roles";
import StatusPage from "./components/Status/StatusPage";

class App extends Component {
  static propTypes = {
    history: PropTypes.shape({}),
    vedtaker: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  };

  constructor() {
    super();
    this.__showUrlTransitionModal = Symbol.for(
      `__PreventTransitionPrompt_${cuid()}`
    );
  }

  state = {
    afterRenderPathname: null,
    allowTransitionCallback: () => {},
    openUrlTransitionModal: false,
    urlTransitionModalTitle: "",
    urlTransitionModalBodyText: "",
  };

  componentDidMount = () => {
    this.setState({
      location: window.location.pathname,
      afterRenderPathname: window.location.pathname,
    });
    window[this.__showUrlTransitionModal] = this.showUrlTransitionModal;
  };

  /**
   * Ensure we clean up and remove the reference
   * from the global object
   */
  componentWillUnmount = () => {
    delete window[this.__showUrlTransitionModal];
  };

  componentDidUpdate = () => {
    if (window.location.pathname !== this.state.afterRenderPathname) {
      this.setState({ afterRenderPathname: window.location.pathname });
    }
  };

  handleUrlTransition = (location) => {
    this.setState({ location });
    return Symbol.keyFor(this.__showUrlTransitionModal);
  };

  showUrlTransitionModal = (allowTransitionCallback) => {
    const { afterRenderPathname } = this.state;
    allowTransitionCallback(false);

    if (
      afterRenderPathname === paths.klage.shortUrl &&
      this.getHasIkkeFerdigbehandletVedtaker()
    ) {
      this.setState({
        urlTransitionModalTitle:
          "Det finnes vedtak som ikke er ferdigbehandlet",
        urlTransitionModalBodyText:
          "Vil du gå videre uten å ferdigbehandle vedtakene?",
      });
    }

    this.setState({ openUrlTransitionModal: true, allowTransitionCallback });
  };

  handleCloseUrlTransitionModal = () =>
    this.setState({ openUrlTransitionModal: false });

  handleFortsettUrlTransitionModal = () => {
    const { history } = this.props;
    const { location, allowTransitionCallback } = this.state;
    let pathname = location && location.pathname;
    if (pathname) {
      allowTransitionCallback(true);
      history.push(pathname);
    }
    this.setState({ openUrlTransitionModal: false });
  };

  getHasIkkeFerdigbehandletVedtaker = () => {
    if (!this.IsOnlyForJuridisk()) return false;
    const { vedtaker } = this.props;

    var vedtakerArray = Object.values(vedtaker);
    var hasIkkeFerdigbehandletVedtaker = false;
    for (const vedtak of vedtakerArray) {
      if (vedtak.vedtaksDato === null) {
        hasIkkeFerdigbehandletVedtaker = true;
        break;
      }
    }

    return hasIkkeFerdigbehandletVedtaker;
  };

  IsOnlyForJuridisk = () => {
    return authorized(juridiskArray);
  };
  promptWhen = () => {
    const { afterRenderPathname } = this.state;

    if (
      afterRenderPathname === paths.klage.shortUrl &&
      this.getHasIkkeFerdigbehandletVedtaker()
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {
      openUrlTransitionModal,
      urlTransitionModalTitle,
      urlTransitionModalBodyText,
    } = this.state;

    return (
      <div>
        <NavigationBar />
        <Prompt when={this.promptWhen()} message={this.handleUrlTransition} />
        {openUrlTransitionModal && (
          <ModalGeneric
            id="urlTransitionModal"
            modalTitle={urlTransitionModalTitle}
            open={openUrlTransitionModal}
            openModal={this.showUrlTransitionModal}
            closeModal={this.handleCloseUrlTransitionModal}
            submit={this.handleFortsettUrlTransitionModal}
            submitButtonText="Fortsett"
          >
            <p>{urlTransitionModalBodyText}</p>
          </ModalGeneric>
        )}
        <FlashMessagesListContainer />
        <Switch>
          <Redirect exact path="/" to={paths.landingPage} />
          <PublicRoute
            exact
            forcePublicRoute={true}
            path={paths.version}
            component={VersionPage}
          />
          <PublicRoute exact path={paths.login} component={LoginPage} />
          <Redirect exact path={paths.logout} to={paths.login} />
          <PublicRoute
            exact
            path={paths.redirect}
            component={RedirectPage}
            forcePublicRoute
          />
          <PrivateRoute exact path={paths.profil} component={ProfilePage} />
          <AuthorizedRoutes
            path={paths.landingPage}
            roles={[
              ...bypatruljenArray,
              skiftlogg,
              ...juridiskArray,
              ...innsynArray,
              ileggelseadmin,
            ]}
            component={LandingPageContainer}
          />
          <Redirect
            path={paths.inntauing.ileggelser.detalj.shortUrl}
            to={
              authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
              ])
                ? paths.ileggelser.oversikt.detalj.shortUrl.routeMatcher
                : paths.innsyn.ileggelser.detalj.shortUrl
            }
          />
          <AuthorizedRoutes
            path={paths.ileggelser.shortUrl}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={IleggelserRoutes}
          />
          <AuthorizedRoutes
            path={paths.miljogebyrer.shortUrl}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={MiljogebyrerRoutes}
          />
          <AuthorizedRoutes
            path={paths.uregistrerte.shortUrl}
            roles={[
              ...Object.values(bypatruljen),
              ...Object.values(juridisk),
              innsyn.vaktsentralen,
              innsyn.serviceavdeling,
            ]}
            component={UregistrerteRoutes}
          />
          <AuthorizedRoutes
            path={paths.klage.default}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={KlageRoutes}
          />
          <AuthorizedRoutes
            path={paths.rapporter.shortUrl}
            roles={[
              ...Object.values(bypatruljen),
              skiftlogg,
              ...Object.values(juridisk),
            ]}
            component={RapporterRoutes}
          />
          <AuthorizedRoutes
            path={paths.innkreving.shortUrl}
            roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
            component={InnkrevingRoutes}
          />
          <AuthorizedRoutes
            path={paths.innsyn.shortUrl}
            roles={[
              innsyn.serviceavdelingVaktsentralen,
              innsyn.serviceavdeling,
              innsyn.vaktsentralen,
              innsyn.kemnerkontoret,
              innsyn.seksjonssjefVaktsentralen,
              ...Object.values(bypatruljen),
              ...Object.values(juridisk),
            ]}
            component={InnsynRoutes}
          />
          <AuthorizedRoutes
            exact
            path={paths.grunndata.page}
            roles={[
              bypatruljen.saksbehandler,
              bypatruljen.saksbehandleradmin,
              bypatruljen.avdelingdirektor,
              bypatruljen.seksjonssjefStab,
              bypatruljen.seksjonssjef,
              bypatruljen.driftleder,
              ...Object.values(juridisk),
              innsyn.serviceavdelingVaktsentralen,
              innsyn.seksjonssjefVaktsentralen,
              innsyn.serviceavdeling,
              innsyn.vaktsentralen,
            ]}
            component={GrunndataPage}
          />
          <Redirect
            path={paths.grunndata.shortUrl}
            to={paths.grunndata.shortUrl}
          />
          <AuthorizedRoutes
            path={paths.linker}
            roles={[
              ...bypatruljenAndJuridiskArray,
              innsyn.seksjonssjefVaktsentralen,
              innsyn.serviceavdelingVaktsentralen,
              innsyn.serviceavdeling,
              innsyn.vaktsentralen,
            ]}
            component={Linker}
          />
          <AuthorizedRoutes
            path={paths.status}
            roles={[ileggelseadmin]}
            component={StatusPage}
          />
          <Route component={<div>Fant ikke siden!</div>} />
        </Switch>
      </div>
    );
  }
}

export default App;
