import { ILEGGELSE_SERVICE_BASE_URL } from "../config.ts";

// -- Kjoretoy
export const PUT_KJORETOY_URL = (kjoretoyId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoy/${kjoretoyId}`;

// - Kjoretoy Merker
export const GET_KJORETOY_MERKER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoy_merker`;
export const POST_KJORETOY_MERKE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoy_merker`;
export const PUT_KJORETOY_MERKE_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoy_merker/${id}`;

// - Kjoretoy Typer
export const GET_KJORETOY_TYPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoytyper`;
export const POST_KJORETOY_TYPE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoytyper`;
export const PUT_KJORETOY_TYPE_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/kjoretoytyper/${id}`;
