export function getEnvironmentAppendix() {
  if (typeof window === "undefined") {
    return "";
  }
  switch (window["REACT_APP_ENV"] || process.env.REACT_APP_ENV) {
    case "local":
      return "";
    case "dev":
      return "_DEV";
    case "externaldev":
      return "_EXTERNALDEV";
    case "test":
      return "_TEST";
    case "stage":
      return "_STAGE";
    case "prod":
      return "_PROD";
    default:
      return "";
  }
}
