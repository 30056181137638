import React, { useEffect, useState } from "react";
import Table from "../Common/Tables/BaseTable";
import "./StatusPage.css";
import SpinLoader from "../Common/SpinLoader";

// TODO: feilhåndtering
// TODO: Caching
export const Status = ({ getIneStatus }) => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    getIneStatus().then((response) => {
      setStatus(response);
    });
  }, [getIneStatus]);

  if (status.length === 0) {
    return (
      <div className="status-page-loading-wrapper">
        <SpinLoader />
      </div>
    );
  }

  return (
    <div className="status-page-wrapper">
      <h1>Status</h1>
      <Table
        id="statusIne"
        headerRow={[
          "Metode",
          "Timer forløpt",
          "Prosent OK",
          "Maks forventa intervall",
          "Detaljer",
          "Tid",
          "",
        ]}
        defaultStyle
        striped
      >
        <tbody>
          {status.map((item) => (
            <tr key={item.method}>
              <td>{item.method}</td>
              <td>{getTidITimerOgMinutter(item.value)}</td>
              <td>{getProsent(item.percent_OK)}</td>
              <td>{item.percentExplanation}</td>
              <td>{item.description}</td>
              <td>{`${item.millisecondsElapsed} ms`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const getTidITimerOgMinutter = (timer) => {
  if (timer === -1) return "-";

  const timerHeltall = Math.floor(timer);
  const minutter = Math.round((timer - timerHeltall) * 60);
  return `${timerHeltall} t ${minutter} min`;
};

const getProsent = (prosent) => {
  if (prosent === -1) return "-";
  return `${prosent}%`;
};
