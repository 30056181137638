import moment from "moment";
import * as errorMessages from "../constants/ErrorMessages";
import * as datetimeUtils from "./datetimeUtils";

export const required = (id, value) => {
  return value ? null : id + " er påkrevd";
};

export const mustBeAnInteger = (value) =>
  Number.isInteger(parseFloat(value, 10))
    ? null
    : errorMessages.mustBeAnInteger;

export const mustBeAPositiveInteger = (value) =>
  Number.isInteger(parseFloat(value, 10)) && parseInt(value, 10) > 0
    ? null
    : errorMessages.mustBeAPositiveInteger;

export const fraDateTimeTilDateTimeValidation = (state, handleChange) => {
  const { fraDato, fraTid, tilDato, tilTid } = state;
  let fraDatoValidationErrorText = null;
  let fraTidValidationErrorText = null;
  let tilDatoValidationErrorText = null;
  let tilTidValidationErrorText = null;

  if (fraDato && !moment.isMoment(fraDato)) {
    fraDatoValidationErrorText = errorMessages.dateFromIsNotValid(fraDato);
  }
  if (fraTid && !moment.isMoment(fraTid)) {
    fraTidValidationErrorText = errorMessages.TimeFromIsNotValid;
  }
  if (tilDato && !moment.isMoment(tilDato)) {
    tilDatoValidationErrorText = errorMessages.DateToIsNotValid;
  }
  if (tilTid && !moment.isMoment(tilTid)) {
    tilTidValidationErrorText = errorMessages.TimeToIsNotValid;
  }

  if (moment.isMoment(tilDato) && !fraDato) {
    fraDatoValidationErrorText = errorMessages.DateFromIsRequired;
  }
  if (moment.isMoment(fraDato) && !tilDato) {
    tilDatoValidationErrorText = errorMessages.DateToIsRequired;
  }

  if (fraTid && !fraDato) {
    fraDatoValidationErrorText = errorMessages.DateFromIsRequired;
  }
  if (tilTid && !tilDato) {
    tilDatoValidationErrorText = errorMessages.DateToIsRequired;
  }

  if (
    moment.isMoment(fraDato) &&
    moment.isMoment(tilDato) &&
    datetimeUtils.StartDateIsLongerThanEndDate(fraDato, tilDato)
  ) {
    fraDatoValidationErrorText = errorMessages.FraDatoAndTilDato;
  }

  if (
    moment.isMoment(fraDato) &&
    moment.isMoment(fraTid) &&
    moment.isMoment(tilDato) &&
    moment.isMoment(tilTid) &&
    datetimeUtils.StartDateIsLongerThanEndDate(
      datetimeUtils.mergeDateTime(fraDato, fraTid),
      datetimeUtils.mergeDateTime(tilDato, tilTid)
    )
  ) {
    fraDatoValidationErrorText = errorMessages.FraDatoAndTilDato;
  }

  handleChange("fraDatoValidationErrorText", fraDatoValidationErrorText);
  handleChange("fraTidValidationErrorText", fraTidValidationErrorText);
  handleChange("tilDatoValidationErrorText", tilDatoValidationErrorText);
  handleChange("tilTidValidationErrorText", tilTidValidationErrorText);

  return fraDatoValidationErrorText ||
    fraTidValidationErrorText ||
    tilDatoValidationErrorText ||
    tilTidValidationErrorText
    ? false
    : true;
};

export const getDateTimeValidationError = (dateTimes) => {
  const { fraDato, fraTid, tilDato, tilTid } = dateTimes;
  let fraDatoValidationErrorText = null;
  let fraTidValidationErrorText = null;
  let tilDatoValidationErrorText = null;
  let tilTidValidationErrorText = null;

  if (fraDato && !moment.isMoment(fraDato)) {
    fraDatoValidationErrorText = errorMessages.dateFromIsNotValid;
  }
  if (fraTid && !moment.isMoment(fraTid)) {
    fraTidValidationErrorText = errorMessages.TimeFromIsNotValid;
  }
  if (tilDato && !moment.isMoment(tilDato)) {
    tilDatoValidationErrorText = errorMessages.DateToIsNotValid;
  }
  if (tilTid && !moment.isMoment(tilTid)) {
    tilTidValidationErrorText = errorMessages.TimeToIsNotValid;
  }

  if (moment.isMoment(tilDato) && !fraDato) {
    fraDatoValidationErrorText = errorMessages.DateFromIsRequired;
  }
  if (moment.isMoment(fraDato) && !tilDato) {
    tilDatoValidationErrorText = errorMessages.DateToIsRequired;
  }

  if (fraTid && !fraDato) {
    fraDatoValidationErrorText = errorMessages.DateFromIsRequired;
  }
  if (tilTid && !tilDato) {
    tilDatoValidationErrorText = errorMessages.DateToIsRequired;
  }

  if (
    moment.isMoment(fraDato) &&
    moment.isMoment(tilDato) &&
    datetimeUtils.StartDateIsLongerThanEndDate(fraDato, tilDato)
  ) {
    fraDatoValidationErrorText = errorMessages.FraDatoAndTilDato;
  }

  if (
    moment.isMoment(fraDato) &&
    moment.isMoment(fraTid) &&
    moment.isMoment(tilDato) &&
    moment.isMoment(tilTid) &&
    datetimeUtils.StartDateIsLongerThanEndDate(
      datetimeUtils.mergeDateTime(fraDato, fraTid),
      datetimeUtils.mergeDateTime(tilDato, tilTid)
    )
  ) {
    fraDatoValidationErrorText = errorMessages.FraDatoAndTilDato;
  }

  return {
    fraDatoValidationErrorText,
    fraTidValidationErrorText,
    tilDatoValidationErrorText,
    tilTidValidationErrorText,
  };
};

export const fromDateToDateRequiredValidation = (
  fraDato,
  tilDato,
  handleChange
) => {
  if (!fraDato && !tilDato) {
    handleChange(
      "fraDatoValidationErrorText",
      errorMessages.DateFromIsRequired
    );
    return false;
  }
  return true;
};
