import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoadButton from "../../../Common/LoadButton";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";

import { getMiljogebyr as getMiljogebyrAction } from "../../../../actions/miljogebyrerActions";
import {
  postMiljogebyrEttersendelsebrev as postMiljogebyrEttersendelsebrevAction,
  getMiljogebyrEttersendelser as getMiljogebyrEttersendelserAction,
} from "../../../../actions/miljogebyrEttersendelserActions";
import { getMiljoEttersendelserEttersendelsebrevPdf as getMiljoEttersendelserEttersendelsebrevPdfAction } from "../../../../actions/miljoEttersendelsebrevActions";

class SendTilSvarUtContainer extends React.Component {
  static propTypes = {
    latestEttersendelseId: PropTypes.string,
    miljogebyr: PropTypes.object,
    getMiljogebyr: PropTypes.func.isRequired,
    postMiljogebyrEttersendelsebrev: PropTypes.func.isRequired,
    getMiljogebyrEttersendelser: PropTypes.func.isRequired,
    getMiljoEttersendelserEttersendelsebrevPdf: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
    loadButtonStyle: PropTypes.object,
  };

  static defaultProps = {
    loadButtonStyle: { marginLeft: "20px" },
  };

  state = {
    showSendTilSvarUtSpinner: false,
  };

  handleApiCall = async (ettersendelseId) => {
    const {
      miljogebyr,
      postMiljogebyrEttersendelsebrev,
      getMiljogebyr,
      getMiljogebyrEttersendelser,
      reloadHistorikk,
    } = this.props;
    this.setState(() => ({ showSendTilSvarUtSpinner: true }));
    return postMiljogebyrEttersendelsebrev(ettersendelseId)
      .then(() => {
        this.setState(() => ({ showSendTilSvarUtSpinner: false }));
        getMiljogebyr(miljogebyr.id);
        getMiljogebyrEttersendelser(miljogebyr.id);
        reloadHistorikk();
        return Promise.resolve();
      })
      .catch((error) => {
        this.setState(() => ({ showSendTilSvarUtSpinner: false }));
        return Promise.reject(error);
      });
  };

  showNoFolgebrevWarning = () => {
    const { miljogebyr } = this.props;

    return (
      (!miljogebyr.folgebrevId || miljogebyr.folgebrevId === 1) &&
      ((miljogebyr.overtredelse1 &&
        (miljogebyr.overtredelse1.id === 48 ||
          miljogebyr.overtredelse1.id === 66)) ||
        (miljogebyr.overtredelse2 &&
          (miljogebyr.overtredelse2.id === 48 ||
            miljogebyr.overtredelse2.id === 66)) ||
        (miljogebyr.overtredelse3 &&
          (miljogebyr.overtredelse3.id === 48 ||
            miljogebyr.overtredelse3.id === 66)))
    );
  };

  render() {
    const { showSendTilSvarUtSpinner } = this.state;
    const { miljogebyr, latestEttersendelseId, loadButtonStyle } = this.props;

    if (
      latestEttersendelseId &&
      miljogebyr.isTilEttersendelse &&
      !miljogebyr.isMakulert &&
      !miljogebyr.isBetalt
    ) {
      return (
        <>
          <ModalGenericPostCancelContainer
            id={latestEttersendelseId}
            submitButtonText="Send til Svar ut"
            submittingButtonText="Sender til Svar ut..."
            successPostCallFlashMessage="Ettersendelsebrev generert"
            errorPostCallFlashMessage="Ettersendelsebrev ble ikke generert"
            handleApiCall={this.handleApiCall}
            modalTitle={`Send miljøgebyr ${miljogebyr.ileggelsesnummer} til Svar ut`}
            submitButtonDisabled={showSendTilSvarUtSpinner}
            showSubmitSpinner={showSendTilSvarUtSpinner}
            openModalComponentFunction={(props) => (
              <LoadButton
                isLoading={showSendTilSvarUtSpinner}
                buttonProps={{
                  className: "btn btn-bym-standard",
                  style: loadButtonStyle,
                  onClick: props.openModal,
                  disabled: showSendTilSvarUtSpinner,
                }}
                spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
              >
                Send til Svar ut
              </LoadButton>
            )}
          >
            {this.showNoFolgebrevWarning() && (
              <p className="error">
                Du er i ferd med å sende et ettersendelsesbrev uten følgebrev.
                Er du sikker på at du fortsatt vil sende ettersendelsesbrevet?
              </p>
            )}
            <p>
              {`Ønsker du å sende et ettersendelsesbrev for miljøgebyr med ileggelsesnummer ${miljogebyr.ileggelsesnummer} til Svar ut?`}
            </p>
          </ModalGenericPostCancelContainer>
        </>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    postMiljogebyrEttersendelsebrev: (ettersendelseId) =>
      dispatch(postMiljogebyrEttersendelsebrevAction(ettersendelseId)),
    getMiljogebyrEttersendelser: (miljogebyrId) =>
      dispatch(getMiljogebyrEttersendelserAction(miljogebyrId)),
    getMiljoEttersendelserEttersendelsebrevPdf: (miljoEttersendelseBrevId) =>
      dispatch(
        getMiljoEttersendelserEttersendelsebrevPdfAction(
          miljoEttersendelseBrevId
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendTilSvarUtContainer);
