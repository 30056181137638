import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_INE_STATUS_SUCCESS = "GET_INE_STATUS_SUCCESS";
export const GET_INE_STATUS_FAILURE = "GET_INE_STATUS_FAILURE";

export function getIneStatus() {
  return (dispatch) => {
    return axios
      .get(api.GET_INE_STATUS)
      .then(handle.success(dispatch, GET_INE_STATUS_SUCCESS))
      .catch(handle.error(dispatch, GET_INE_STATUS_FAILURE));
  };
}
