import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  FormLabel,
  InputGroup,
  Button,
  Tooltip,
} from "react-bootstrap";
import classnames from "classnames";
import "./EditableFieldJira";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

class EditableFieldJira extends Component {
  static propTypes = {
    isNumber: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    containsNumber: PropTypes.bool,
    isRequired: PropTypes.string,
    inputLength: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    inputId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    label: PropTypes.string,
    valueFront: PropTypes.string,
    valuePostfix: PropTypes.string,
    disabled: PropTypes.bool,
  };
  state = {
    editing: false,
    text: "",
    error: "",
  };

  componentDidMount() {
    this.setState({ text: this.props.value });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.value !== this.state.text)
      this.setState({ text: newProps.value });
  }

  click = () => {
    if (!this.state.editing) {
      this.setState({ editing: true, error: "" });
    }
  };

  cancel = () => {
    this.setState({ editing: false, text: this.props.value, error: "" });
  };

  isValid(text) {
    const { inputLength, maxLength, containsNumber } = this.props;
    let val = text ? text.toString() : "";
    if (val === "") {
      this.setState({ error: "Dette feltet er påkrevd." });
      return false;
    }
    if (this.props.isNumber && isNaN(val.replace(",", "."))) {
      //replace(/\s/g, ""
      this.setState({ error: "Teksten kan kun bestå av tall." });
      return false;
    }
    if (containsNumber) {
      if (!val.match(/\d/) || val === "0") {
        this.setState({ error: "Tekst må inneholde et tall." });
        return false;
      }
    }
    if (inputLength) {
      if (val.length < inputLength) {
        this.setState({
          error: `Lengden på teksten må være mellom ${inputLength.toString()} og ${maxLength.toString()} tegn`,
        });
        return false;
      }
    }
    if (maxLength) {
      if (val.length !== maxLength) {
        this.setState({
          error: `Lengden på teksten må være mellom ${inputLength.toString()} og ${maxLength.toString()} tegn`,
        });
        return false;
      }
    }
    this.setState({ error: "" });
    return true;
  }

  submit = () => {
    this.setState({ editing: false });
    if (this.isValid(this.state.text)) {
      let value = this.state.text;
      if (this.props.containsNumber) {
        value = value.replace(",", ".");
      }
      let ev = { target: { name: this.props.name, value: value } };
      this.props.onSubmit(ev);
      this.setState({ error: "" });
    } else {
      this.cancel();
    }
  };

  onBlur = (e) => {
    if (!this.node.contains(e.relatedTarget)) {
      this.cancel();
    }
  };

  handleChange = (ev) => {
    let value = ev.target.value;
    this.setState({ text: value });
    this.isValid(value);
  };

  renderEditing() {
    const { label, name } = this.props;
    const { text, error, editing } = this.state;
    const value = text ? text.toString() : "";
    return (
      <FormGroup
        id={this.props.id}
        className={classnames("editable-field-form", { "has-error": error })}
      >
        {label && <FormLabel>{label}</FormLabel>}
        <div
          className="editable-field-jira"
          ref={(node) => (this.node = node)}
          onBlur={this.onBlur}
        >
          <input
            className="form-control editModus"
            name={name}
            // placeholder={placeholder}
            id={this.props.inputId}
            value={value}
            onChange={this.handleChange}
            autoFocus={editing}
          />
          <div className="editable-field-jira-buttons">
            <Button bsSize="small" onClick={this.cancel}>
              <FontAwesomeIcon icon={faTimes} className="cancel" />
            </Button>
            <Button bsSize="small" onClick={this.submit} type="submit">
              <FontAwesomeIcon icon={faCheck} className="save" />
            </Button>
          </div>
        </div>
        {error.length > 0 && (
          <Tooltip placement="bottom" className="in" id="tooltip-error">
            {error}
          </Tooltip>
        )}
      </FormGroup>
    );
  }

  renderNormal() {
    const { label, valueFront, placeholder, valuePostfix } = this.props;
    const { text, error } = this.state;
    const value =
      (text ? text.toString() : placeholder ? placeholder : "-") +
      (valuePostfix ? " " + valuePostfix : "");
    return (
      <FormGroup
        id={this.props.id}
        className={classnames("editable-field-form", { "has-error": error })}
        onClick={this.click}
      >
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup className="editable-field">
          <span className="form-control editable-field-textarea">{value}</span>
          {valueFront && (
            <InputGroup.Append>
              {" "}
              <span>{valueFront}</span>
            </InputGroup.Append>
          )}
          <InputGroup.Append className="editable-field-span">
            <FontAwesomeIcon icon={faPencilAlt} style={{ marginTop: 10 }} />
          </InputGroup.Append>
        </InputGroup>
      </FormGroup>
    );
  }

  render() {
    const { editing, text } = this.state;
    if (this.props.disabled)
      return <div className="disabled">{text && text !== "" ? text : "-"}</div>;
    return editing ? this.renderEditing() : this.renderNormal();
  }
}

export default EditableFieldJira;
