import { map, includes, isEmpty } from "lodash";
import moment from "moment";

/****************
 *  Accepted rules seprated by ";"
 *
 *  Example: rules={navn:"required;minLength=8", age:"required;isDigit; compareTo=feltname"}
 *
 * ********************/

/**************************** error messages ***************** */

const requiredMsg = (name, value) => {
  return required(value) ? Capitalize(name) + " er påkrevd" : "";
};

const isDigitMsg = (name, value) => {
  if (value === null) {
    return "";
  }
  return isDigit(value) ? `${Capitalize(name)} må være nummer` : "";
};

function isGuidMsg(name, value) {
  const guidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return (value || value === 0) && !guidRegex.test(value)
    ? `${Capitalize(name)} må være en guid`
    : "";
}

const isPositiveIntegerMsg = (name, value) =>
  isPositiveInteger(value)
    ? ""
    : `${Capitalize(name)} må være et positivt heltall`;

const LengthMsg = (name, value, length) => {
  return hasLength(value, length)
    ? ""
    : `${Capitalize(name)} må være ${length} sifre`;
};
const isEmailMsg = (name, value) => {
  return isEmail(value) && value !== ""
    ? `${Capitalize(name)} er ikke en gyldig epost`
    : "";
};
const isUserNameMsg = (name, value) => {
  return !isUserName(value) || value === ""
    ? `${Capitalize(name)} er ikke et gyldig navn`
    : "";
};
const isDatoMsg = (name, value) => {
  if (value && value !== "") {
    if (!isDate(value)) return `${Capitalize(name)} er ikke en gyldig dato`;
  }
  return "";
};
const isFutureMsg = (name, value) => {
  if (value && value !== "") {
    const lessthantoday = lessThanToday(value);
    if (!isDate(value)) return `${Capitalize(name)} er ikke en gyldig dato`;
    else if (lessthantoday)
      return `${Capitalize(name)} kan ikke være mindre enn èn dag`;
  }
  return "";
};

const isLessThanMsg = (firstName, firstValue, secondName, secondValue) => {
  if (firstValue && firstValue !== "" && secondValue && secondValue !== "") {
    return isLessThan(firstValue, secondValue) === false
      ? `${Capitalize(firstName)} må være mindre en ${secondName}`
      : "";
  }
  return "";
};

const isLargerThanMsg = (firstName, firstValue, secondName, secondValue) => {
  if (firstValue && firstValue !== "" && secondValue && secondValue !== "") {
    return isLargerThan(firstValue, secondValue) === false
      ? `${Capitalize(firstName)} må være større en ${secondName}`
      : "";
  }
  return "";
};

const isSameAsMsg = (firstValue, secondValue) => {
  if (firstValue !== secondValue) {
    return `${Capitalize(firstValue)} er ikke lik ${Capitalize(secondValue)}`;
  }
  return "";
};

const minLengthMsg = (name, value, min) => {
  return minLength(value, min)
    ? `${Capitalize(name)} må bestå av minst ${min} tegn`
    : "";
};
const minValueMsg = (name, value, min) => {
  return minValue(value, min)
    ? `${Capitalize(name)} må bestå av minst ${min} tegn`
    : "";
};

const maxLengthMsg = (name, value, max) => {
  return maxLength(value, max)
    ? `${Capitalize(name)} må bestå av maks ${max} tegn`
    : "";
};

const hasFormatMsg = (
  name,
  value,
  regExpAndFormatExampelCommaSeperatedString
) => {
  const ruleValuesArray = regExpAndFormatExampelCommaSeperatedString.split(",");
  const regExp = ruleValuesArray.length > 0 ? ruleValuesArray[0] : "";
  const formatExampel = ruleValuesArray.length > 1 ? ruleValuesArray[1] : "";
  return hasFormat(value, regExp)
    ? ""
    : `${Capitalize(name)} må være på formen ${formatExampel}`;
};

/************************ rule checkers  ***************************/
const required = (value) => {
  if (value === undefined || value == null) return false;
  else return value === "" || value.length === 0;
};

const isDigit = (value) => {
  return value !== undefined && isNaN(value);
};

const isPositiveInteger = (value) =>
  value === "" ||
  (Number.isInteger(parseFloat(value, 10)) && parseInt(value, 10) > 0);

const hasLength = (value, length) => {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    value.length === parseInt(length, 10)
  );
};

const isEmail = (value) => {
  const notEmailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return value !== undefined && !notEmailRegex.test(value);
};

const isUserName = (value) => {
  const navnRegex = /^([A-ZÅÆØ]{1}[a-zåæø]+\s?-?)*([A-ZÅÆØ]{1}[a-zåæø]+)+$/;
  const valid = value !== null && navnRegex.test(value);
  return valid;
};

const minLength = (value, min) => {
  return (
    value !== undefined && value !== null && value.length < parseInt(min, 10)
  );
};
const minValue = (value, min) => {
  return (
    value !== undefined &&
    value !== null &&
    parseInt(value, 10) < parseInt(min, 10)
  );
};
const maxLength = (value, max) => {
  return (
    value !== undefined && value !== null && value.length > parseInt(max, 10)
  );
};
const isLessThan = (startDate, endDate) => {
  return startDateIslessThanEndDate(startDate, endDate);
};
const isLargerThan = (startDate, endDate) => {
  return endDateIsLargerThanStartDate(startDate, endDate);
};
const hasFormat = (value, regExp) => new RegExp(regExp).test(value);

/********************** function helpers ***************************/
export const toDatetime = (obj) => {
  if (isDate(obj)) return moment(obj).toDate();
};
export const toTimestamp = (obj) => {
  if (isDate(obj)) return moment(obj).valueOf();
};

export const lessThanToday = (obj) => {
  if (isDate(obj)) {
    const yearmonthdays = moment(obj).utc().startOf("day");
    const now = moment().utc().startOf("day");
    const diff = yearmonthdays.diff(now, "days", true);
    return diff < 0 ? true : false;
  }
  return false;
};
export const diffBetweenDates = (start, end) => {
  if (isDate(start) && isDate(end)) {
    const sd = moment(start).utc().startOf("day");
    const ed = moment(end).utc().startOf("day");
    const diff = sd.diff(ed, "days", true);
    return diff;
  }
};
export const startDateIslessThanEndDate = (start, end) => {
  const diff = diffBetweenDates(start, end);
  return diff < 0 || diff === 0 ? true : false;
};

export const endDateIsLargerThanStartDate = (start, end) => {
  const diff = diffBetweenDates(start, end);
  return diff > 0 || diff === 0 ? true : false;
};
export const startDateIsEqualEndDate = (start, end) => {};

export const Capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function isDate(datetime) {
  var dt = moment(datetime);
  return dt.isValid();
}

export function validateSelect(state, key, value) {
  const errors = state.validationError;
  if (value && (value !== "" || value !== "")) {
    delete errors[key];
    return errors;
  }
}
export const CheckRulesStringFormat = (rules) => {
  if (rules.indexOf(";") !== -1) {
    return true;
  } else {
    return false;
  }
};
export function onChangeValidation(e, state, rules) {
  const name = e.target.name;
  const value = e.target.value;
  //const rules = e.target.getAttribute("rules");
  if (name in rules) {
    RunRules(name, value, rules[name], state);
  }
}

export const IsValidated = (state, rules) => {
  OnSubmitValidation(state, rules);
  const validationError = state.validationError;
  const isValid = isEmpty(validationError);
  return { isValid, validationError };
};

export function OnSubmitValidation(state, rules) {
  for (let r in rules) {
    if (rules[r] !== "") {
      RunRules(r, state[r], rules[r], state);
    }
  }
}

const RunRules = (name, value, rules, state) => {
  const dict = RulesDictionary(rules);
  checkValidationRules(name, value, dict, state);
};
const RulesDictionary = (rules) => {
  if (typeof rules === typeof String() && rules !== "") {
    const splitRules = rules.split(";");
    let dict = {};
    map(splitRules, (item) => {
      if (includes(item, "=")) {
        const sp = item.split("=");
        dict[sp[0]] = sp[1];
      } else {
        dict[item] = "";
      }
    });
    return dict;
  }
};

function checkValidationRules(name, value, rules, state) {
  const required = "required";
  const isGuid = "isGuid";
  const isDigit = "isDigit";
  const isNumber = "isNumber";
  const isPositiveInteger = "isPositiveInteger";
  const length = "length";
  const isEmail = "isEmail";
  const isName = "isName";
  const isUserName = "isUserName";
  const isDato = "isDato";
  const isFuture = "isFuture";
  const minValue = "minValue";
  const minLength = "minLength";
  const maxLength = "maxLength";
  const hasFormat = "hasFormat";
  const isLessThan = "isLessThan";
  const isLargerThan = "isLargerThan";
  const isSameAs = "isSameAs";
  const errors = state.validationError;

  if (required in rules) {
    let error = requiredMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isGuid in rules) {
    let error = isGuidMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isDigit in rules || isNumber in rules) {
    let error = isDigitMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isPositiveInteger in rules) {
    let error = isPositiveIntegerMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isEmail in rules) {
    let error = isEmailMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isUserName in rules || isName in rules) {
    let error = isUserNameMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isDato in rules) {
    let error = isDatoMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isFuture in rules) {
    let error = isFutureMsg(name, value);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (isLessThan in rules) {
    const compareToField = rules[isLessThan];
    const compareToValue = state[compareToField];
    let error = isLessThanMsg(name, value, compareToField, compareToValue);
    if (error !== "") {
      errors[name] = error;
      return;
    } else {
      const diff = startDateIslessThanEndDate(value, compareToValue);
      if (diff) {
        delete errors[name];
        delete errors[compareToField];
      }
    }
  }
  if (isLargerThan in rules) {
    const compareToField = rules[isLargerThan];
    const compareToValue = state[compareToField];
    let error = isLargerThanMsg(name, value, compareToField, compareToValue);
    if (error !== "") {
      errors[name] = error;
      return;
    } else {
      const diff = endDateIsLargerThanStartDate(value, compareToValue);
      if (diff) {
        delete errors[name];
        delete errors[compareToField];
      }
    }
  }
  if (isSameAs in rules) {
    const compareToField = rules[isSameAs];
    const compareToValue = state[compareToField];
    let error = isSameAsMsg(value, compareToValue);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (length in rules) {
    let error = LengthMsg(name, value, rules[length]);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (minLength in rules) {
    let error = minLengthMsg(name, value, rules[minLength]);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (minValue in rules) {
    let error = minValueMsg(name, value, rules[minValue]);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (maxLength in rules) {
    let error = maxLengthMsg(name, value, rules[maxLength]);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  if (hasFormat in rules) {
    let error = hasFormatMsg(name, value, rules[hasFormat]);
    if (error !== "") {
      errors[name] = error;
      return;
    }
  }
  delete errors[name];
}
