import { connect } from "react-redux";
import { Status } from "./Status";
import { getIneStatus } from "src/actions/ineStatusActions";
import { bindActionCreators } from "redux";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getIneStatus }, dispatch);
};

export default connect(null, mapDispatchToProps)(Status);
