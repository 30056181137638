import { ILEGGELSE_SERVICE_BASE_URL } from "../config.ts";

const GET_VEDTAKSKODER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/vedtakskoder`;
const POST_VEDTAKSKODER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/vedtakskoder`;
const PUT_VEDTAKSKODER_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/vedtakskoder/${id}`;
const GET_SINGLE_VEDTAKSKODE_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/vedtakskoder/${id}`;

const ILEGGELSETYPE_TO_VEDTAK_URL = (vedtakskodeId, ileggelsestypeId) => {
  return `${ILEGGELSE_SERVICE_BASE_URL}/vedtakskoder/${vedtakskodeId}/ileggelsestyper/${ileggelsestypeId}`;
};

export {
  GET_VEDTAKSKODER_URL,
  POST_VEDTAKSKODER_URL,
  PUT_VEDTAKSKODER_URL,
  ILEGGELSETYPE_TO_VEDTAK_URL,
  GET_SINGLE_VEDTAKSKODE_URL,
};
