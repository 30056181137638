import { ILEGGELSE_SERVICE_BASE_URL } from "../config.ts";

export const GET_VENTILSTILLINGER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ventilstillinger`;
export const GET_VENTILSTILLING_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ventilstillinger/${id}`;
export const GET_PIGGDEKKONTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/piggdekkontroller`;
export const GET_VENTILSTILLINGER_BILDER_BASE_URL = (ventilstillingId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ventilstillinger/${ventilstillingId}/bilder`;
export const GET_VENTILSTILLING_BILDE_URL = (ventilstillingId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ventilstillinger/${ventilstillingId}/bilder/${bildeId}`;
